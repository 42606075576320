@import url('https://fonts.bunny.net/css2?family=Inter:wght@100..900&display=swap');
@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Global CSS **** //

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: $black;
    }
    &::-webkit-scrollbar-thumb {
        background: $primary;
        padding: 2px;
    }
}
.p-component, .p-inputtext{
    font-family: $font-base;
}
a {
    text-decoration: none;
    color: $black;
    &:hover {
        text-decoration: none;
    }
}

.main-section{
    padding: 0px;
    background-color: $main-bg;
    &.main-bg{
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        background-image: url(../images/main-bg.png);
        overflow: hidden;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            right: 0px;
            opacity: 0;
            background-color: $main-bg;
            animation: fadeInBackground 3s ease forwards;
        }
    }
    .header-menu-section{
        padding: 50px 75px;
        position: relative;
        z-index: 1;
        .language-dropdown{
            background: transparent;
            border: none;
            .p-dropdown-label{
                padding: 0px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: -0.9px;
                color: $white;
            }
            &.p-focus{
                box-shadow: none;
                border: none;
            }
            .p-dropdown-trigger{
                color: $white;
            }
        }
        .view-button {
            height: 75px;
            --border-angle: 0turn;
            --main-bg: conic-gradient(from var(--border-angle),#0E0020,#0E0020 5%,#0E0020 60%,#0E0020 95%);
            border: solid 3px transparent;
            border-radius: 30px;
            --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #BA38F7, #232191 99%, transparent);
            background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
            background-position: center center;
            animation: bg-spin 2s linear infinite;
            box-shadow: 0px 0px 15px $blue-bg;
            padding: 0rem 3.6rem;
            .p-button-label{
                font-size: 24px;
                font-weight: 400;
                letter-spacing: -1.2px;
                position: relative;
                z-index: 2;
            }
            @keyframes bg-spin {
              to {
                --border-angle: 1turn;
              }
            }
            &:hover {
                background: linear-gradient(to right, #BA38F7, #232191) border-box;
            }
        }
          .header-left{
            .logo-img{
                img{
                    &:focus-visible{
                        outline: none;
                    }
                }
            }
          }
    }
    // .ripple-animation{
    //     perspective: 0px;
    //     .rim1, .rim2, .rim3, .rim4{
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         border-radius: 50%;
    //         width: 0em;
    //         height: 0em;
    //         background: #0E0020;
    //         box-shadow: 0px 65px 150px 0px #0F1145;
    //     }
    //     .rim1 {
    //         animation: expand 1s linear 0.1 1;
    //     }
    //     .rim2 {
    //         animation: expand 1s linear 0.5s 1;
    //     }
    //     .rim3 {
    //         animation: expand 1s linear 1s 1;
    //     }
    //     .rim4 {
    //         animation: expand 1s linear 1.5s 1;
    //     }
    // }
}
@keyframes fadeInBackground {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.show-on-mobile{
    display: none;
}
.footer-section{
    color: $white;
    overflow: hidden;
    h2{
        font-size: 96px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -4.8px;
    }
    .custom-margin{
        margin-top: 28rem;
        position: relative;
    }
    .rectangle-shape{
        height: 260px;
        width: 760px;
        border-radius: 130px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: -8rem;
        bottom: -1rem;
        h3{
            font-size: 79.506px;
            font-weight: 600;
            line-height: 80px;
            letter-spacing: -3.975px;
            color: $black;
        }
        &.rotate{
            left: -11rem;
            bottom: 11rem;
            transform: rotate(-45deg);
        }
    }
    .circle-shape{
        width: 290px;
        height: 290px;
        border-radius: 100%;
        position: absolute;
        bottom: 13.5rem;
        left: 30.2rem;
        z-index: 1;
        background: $primary;
        &.purple{
            background: $accent;
            bottom: 0rem;
            left: 64.2rem;
            width: 260px;
            height: 260px;
        }
    }
    .triangle{
        width: 420px;
        height: 420px;
        background: url(../images/polygon-bg.png) no-repeat;
        background-size: contain;
        position: absolute;
        right: 18rem;
        bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        h3{
            font-size: 64px;
            font-weight: 600;
            line-height: 64px;
            letter-spacing: -3.2px;
            text-align: center;
            transform: rotate(-45deg);
            position: relative;
            left: 30px;
            top: -34px;
        }
    }
    .backtotop-shape{
        height: 200px;
        width: 760px;
        border-radius: 130px;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -24.6rem;
        bottom: -3rem;
        h4{
            font-size: 28.33px;
            font-style: normal;
            font-weight: 600;
            line-height: 28.33px;
            letter-spacing: -1.416px;
            margin-right: 15rem;
            cursor: pointer;
            .pi{
                margin-right: 1rem;
                animation: jumpUpfinite 1.5s infinite;
                position: relative;
            }
        }
    }
    .footer-text{
        text{
            font-size: 78px;
            font-weight: 600;
            line-height: 80px;
            letter-spacing: -3.975px;
            color: $black;
            font-family: $font-base;
            &.triangle-text{
                font-size: 64px;
                font-weight: 600;
                line-height: 100%;
                letter-spacing: -3.2px;
            }
        }
        &:hover{
            .rectangle{
                fill: $accent;
            }
            text{
                fill: $white !important;
            }
        }
        &.email{
            &:hover{
                .rectangle{
                    fill: $primary;
                }
            }
        }
        &.back-to-top{
            text{
                font-size: 28px;
                font-weight: 600;
                line-height: 100%;
                letter-spacing: -1.416px;
                fill: $white;
            }
            .rectangle{
                &::before{
                    content: "\e92f";
                    font-family: primeicons;
                    content: "\e92f";
                    position: absolute;
                    display: block;
                    width: 50px;
                    height: 50px;
                    background: #fac;
                    left: 0px;
                    top: 10px;
                    text-align: center;
                    z-index: 999999999999;
                }
            }
            &:hover{
                .rectangle{
                    fill: $white;
                }
                text{
                    fill: $black !important;
                }
            }
        }
    }
}
.language-dropdown-panel{
    margin-top: 1.5rem;
    margin-left: -5rem;
    &.language-panel{
        margin-left: -5rem;
    }
}
.p-dropdown-panel{
    border-radius: 15px;
    border: 1.687px solid rgba(211, 221, 233, 0.18);
    background: #332447;
}
.p-dropdown-items-wrapper{
    border-radius: 10px;
    max-height: 295px !important;
    .p-dropdown-items{
        margin: 0px;
        padding: 10px;
        .p-dropdown-item{
            background: #332447;
            border: 1px solid #332447;
            font-size: 15px;
            padding: 0.65rem 1rem;
            color: $white;
            font-size: 20px;
            font-weight: 500;
            line-height: 160%;
            &.p-highlight{
                border-radius: 8px;
                border: 1px solid #504361;
                background: #3E2E54;
            }
            &:hover{
                border-radius: 8px;
                border: 1px solid #504361;
                background: #3E2E54;
            }
        }
    }
    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: #574C65;
        padding: 2px;
        border-radius: 6px;
    }
}

.p-toast{
    opacity: 1;
    z-index: 9999 !important;
    max-width: 26rem;
    .p-toast-message{
        &.p-toast-message-success{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #2BA079;
                border-radius: 2px;
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #ff5757;
                border-radius: 2px;
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #cc8925;
                border-radius: 2px;
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #5774cd;
                border-radius: 2px;
            }
            .p-toast-message-icon{
                background: #5774cd !important;
            }
        }
        .p-toast-message-content{
            padding: 12px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: 500;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
            }
        }
    }
}

@keyframes jumpUpfinite {
    0% {
      top: 0;
    }
    50% {
      top: -20px;
    }
    100% {
      top: 0;
    }
}
@keyframes expand {
    0% {
        top: calc(50% - .5em);
        left: calc(50% - .5em);
        width: 1em;
        height: 1em;
        opacity: 1;
        background: #0E0020;
    }
    100% {
        top: calc(50% - 60em);
        left: calc(50% - 60em);
        width: 120em;
        height: 120em;
        opacity: 0;
        background: #0E0020;
    } 
}
@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}
.mobile-footer{
    display: none;
    width: 100%;
    max-width: 90%;
    margin: 0rem auto;
    padding: 2rem 0rem;
    .box{
        background: $white;
        text-align: center;
        padding: 1.5rem;
        border-radius: 3rem;
        margin: 0.45rem 0rem;
        a{
            color: $black;
            h3{
                font-size: 32px;
                line-height: 100%;
                letter-spacing: -1.5px;
                font-weight: 600;
                margin: 0px;
            }
        }
        &.last-box{
            background: $accent;
            a{
                color: $white;
            }
        }
    }
    .scroll-to-top{
        background: $accent;
        border-radius: 8px 0px !important;
        &:hover{
            background: $primary;
            border-radius: 0px 8px !important;
        }
    }
}
.footer-animation{
    canvas{
        width: 100% !important;
    }
}

// Responsive CSS

@media (max-width:991px){
    .main-section{
        .header-menu-section{
            padding: 25px 20px;
            .header-left{
                .logo-img{
                    img{
                        height: 45px;
                    }
                }
            }
            .view-button{
                height: 58px;
                padding: 0rem 1rem;
                .p-button-label{
                    font-size: 18px;
                }
            }
        }
        &.main-bg{
            background-image: url(../images/mobile-main-bg.png);
        }
    }
    .footer-section{
        width: 90%;
        margin: 0 auto;
        h2{
            font-size: 38px;
            line-height: 100%;
            letter-spacing: -2.1px;
        }
    }
    .footer-animation{
        display: none;
    }
    .mobile-footer{
        display: block;
    }
}
.err{
    &-msg{
        font-size: 12px;
        color: $primary;
    }
}
@media (max-width:540px){
    .mobile-footer{
        .box{
            padding: 1rem;
            margin: 0rem 2rem;
            a{
                h3{
                    font-size: 24px;
                }
            }
        }
    }
    @keyframes expand {
        0% {
            top: calc(50% - .5em);
            left: calc(50% - .5em);
            width: 1em;
            height: 1em;
        }
        100% {
            top: calc(50% - 40em);
            left: calc(50% - 40em);
            width: 80em;
            height: 80em;
        } 
    }
}



/* Same as the CSS you provided */
.matter {
    position: relative;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, -1%);
    width: 100%;
    margin: 0 auto;
    height: 700px;
    canvas{
        height: 100% !important;
        width: 100% !important;
        pointer-events: none;
    }
  }
  
  [data-object] {
    --move-x: 0px;
    --move-y: 0px;
    --rotate: 0rad;
    position: absolute;
    border-radius: 100px;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(var(--rotate));
    top: var(--move-y);
    left: var(--move-x);
    pointer-events: none;
  }
  .scroll-to-top {
    width: 400px;
    height: 130px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    align-items: center;
    font-size: 30px;
    background: none;
    border: none;
    color: white;
    background: #F73859;
    font-weight: 600;
    letter-spacing: -1.416px;
    .p-button-label{
        flex: none;
        pointer-events:all;
        cursor: pointer;
    }
    .p-button-icon{
        font-size: 20px;
        position: relative;
        top: 2px;
        right: 10px;
    }
    &:focus{
        box-shadow: none;
    }
  }
.input-group {
    background: white;
    border-radius: 100px;
    width: 560px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
        color: black;
        font-weight: 600;
        font-size: 58px;
        display: block;
        line-height: 100%;
        pointer-events:all;
        cursor: pointer;
        letter-spacing: -3.975px;
    }   
    &.polygon {
        height: 345px;
        width: 450px;
        aspect-ratio: 1;
        background:url('../../assets/images/polygon-bg.png') no-repeat;
        background-position: 0px -20px;
        background-size: 90%;
        label {
            font-size: 48px;
            position: relative;
            top: 2rem;
            a{
                color: $black;
            }
        }
    }
    &.circle-1{
        width:200px;
        height: 200px;
        border-radius: 50%;
        background: $primary;
    }
    &.circle-2{
        width:180px;
        height: 180px;
        border-radius: 50%;
        background: $accent;
    }
}

@media (min-width:993px) and (max-width:1199px){
    .footer-section h2 {
        font-size: 75px;
        letter-spacing: -3.8px;
    }
}
@media (max-width:768px){
    .show-on-mobile{
        display: block;
    }
    .hide-on-mobile{
        display: none;
    }
}